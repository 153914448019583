@import url("../../../App.css");

.cl-logo-container {
  height: 100px;
}

.cl-welcome-label {
  font-size: 26px;
  font-weight: 600;
  color: var(--heading-color);
  text-align: center;
  margin-top: 2rem;
}

.cl-sub-label {
  font-size: 18px;
  font-weight: 500;
  color: var(--heading-color);
  text-align: center;
  margin-bottom: 3rem;
}

.cl-input {
  margin-bottom: 15px;
}

.cl-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
}
