.commonScreenContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}

@media (max-width: 767px) {
  .commonScreenContent {
    padding-left: 0.2em;
    padding-right: 0.2em;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .commonScreenContent {
    padding-left: 0.6em;
    padding-right: 0.6em;
  }
}
