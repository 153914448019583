@import url("../../../App.css");

.nb-container {
  background-color: #d3ddff;
}

.nb-container-white {
  background-color: var(--white);
}

.nb-item-container {
  background-color: #d3ddff;
  color: var(--black);
  font-size: 15px;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-thickness: 1.8px;
  text-underline-offset: 5px;
  cursor: pointer;
}

.nb-item-container-white {
  background-color: var(--white);
}

.home-logo-parent-container {
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 250px;
}

.home-logo-container {
  margin: 0;
  padding: 0;
  width: 50%;
}

.nav-btn {
  color: var(--pastel-002);
}

.nav-btn:hover {
  color: var(--pastel-002);
}

@media (max-width: 767px) {
  .nb-item-container {
    background-color: #d3ddff;
    color: var(--black);
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    text-align: end;
  }

  .nav-bar-menu-container {
    margin-top: 15px;
    text-align: end;
  }

  .nb-item-container-white {
    background-color: var(--white);
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .nb-item-container {
    background-color: #d3ddff;
    color: var(--black);
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    text-align: end;
  }

  .nav-bar-menu-container {
    margin-top: 15px;
    text-align: end;
  }

  .nb-item-container-white {
    background-color: var(--white);
  }
}
