@import url("../../../App.css");

.ccl-list-row-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
  border-radius: 5px;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--pastel-002);
  padding: 5px 15px;
}

.ccl-row-business-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ccl-row-business-label {
  font-size: 16px;
  color: var(--black);
  font-weight: 600;
}

.ccl-row-loc-label {
  font-size: 14px;
  color: var(--gray);
}

.ccl-row-amount-label {
  font-size: 20px;
  font-weight: 600;
  color: var(--black);
}

.ccl-redeem-container {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 5px;
}

.ccl-redeem-label {
  width: fit-content;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 600;
  color: var(--button-blue);
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 1.5px;
}

.ccl-request-label {
  font-size: 12.5px;
  color: var(--green-success);
  margin: 4px 0;
}
