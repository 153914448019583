@import url("../../../App.css");

.sub-text-label {
  font-size: 16px;
  margin-bottom: 1em;
  font-weight: 400;
  color: var(--black);
}

@media (max-width: 767px) {
  .sub-text-label {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sub-text-label {
    font-size: 15px;
  }
}
