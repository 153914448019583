@import url("../../App.css");

.common-form-border {
  width: 80%;
  align-self: center;
  padding: 15px 10px;
  margin: 5px 0px;
  border-radius: 4px;
  border-color: #f3f3f3;
  border-style: solid;
  background-color: var(--white);
}

@media (max-width: 767px) {
  .common-form-border {
    width: 95%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .common-form-border {
    width: 90%;
  }
}
