@import url("../../../App.css");
.top-btn {
  color: var(--white);
  background-color: var(--button-blue);
  font-size: 14px;
  width: 30%;
  height: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--button-blue);
}

.top-btn:hover {
  color: var(--button-blue);
  background-color: var(--white);
  border-radius: 4px;
  border-color: var(--button-blue);
  border-width: 1px;
}

.top-inverted-btn {
  color: var(--button-blue);
  background-color: var(--white);
  font-size: 14px;
  width: 30%;
  height: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--button-blue);
}

.top-inverted-btn:hover {
  color: var(--white);
  background-color: var(--button-blue);
  border-radius: 4px;
  border-color: var(--button-blue);
  border-width: 1px;
}

@media (max-width: 767px) {
  .top-btn {
    width: 50%;
  }

  .top-btn-sm {
    width: 40%;
    font-size: 12px;
  }

  .top-inverted-btn {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .top-btn {
    width: 50%;
  }
  .top-inverted-btn {
    width: 50%;
  }
  .top-btn-sm {
    width: 40%;
    font-size: 12px;
  }
}
