@import url("../../../../App.css");

.eb-loc-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  margin-bottom: 5px;
  border-radius: 5px;
  border-color: var(--gray);
  border-width: 0.75px;
  border-style: solid;
}

.eb-loc-row-content-container {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  flex-grow: 1;
}

.eb-loc-row-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.eb-loc-row-btn-label {
  text-align: center;
  font-size: 14px;
  color: var(--button-color);
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--button-color);
  padding: 5px 25px;
  cursor: pointer;
}
