@import url("../../App.css");

.hp-nav-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #d3ddff;
  height: 45px;
}
/* background-color: #eef8f7; */

.hp-logo-container {
  height: 45px;
}

.nav-item-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-product-btn {
  font-size: 16px;
  font-weight: 500;
  color: var(--white);
  text-decoration: underline;
  margin: 0px 10px;
  cursor: pointer;
  letter-spacing: 1px;
  text-underline-offset: 5px;
  text-decoration-thickness: 1.2px;
  text-shadow: #f3c625 1px 0 1px;
  padding: 5px;
}

.nav-menu-list-container {
  background-color: #f6f6f6;
}

.black-font {
  color: var(--heading-color);
  text-shadow: none;
}

.nav-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  aspect-ratio: 6/2;
}

@media (max-width: 767px) {
  .nav-product-btn {
    font-size: 14.5px;
    margin: 0px 7px;
    letter-spacing: 0.75px;
    text-underline-offset: 4px;
    text-decoration-thickness: 1px;
    padding: 3px;
  }
  .nav-img {
    aspect-ratio: 4.5/2;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
}
