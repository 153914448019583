@font-face {
  font-family: "Noto Nastaliq Urdu";
  src: url("./assets/font/NotoNastaliqUrdu.ttf") format("truetype");
}

@font-face {
  font-family: "Gistesy";
  src: url("./assets/font/Gistesy.ttf") format("truetype");
}

@font-face {
  font-family: "JosefinSans";
  src: url("./assets/font/JosefinSans.ttf") format("truetype");
}

@font-face {
  font-family: "RobotoCondensed";
  src: url("./assets/font/RobotoCondensed.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/poppins/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/font/poppins/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "HindSiliguri";
  src: url("./assets/font/hind-siliguri/HindSiliguri-Light.ttf")
    format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "HindSiliguri";
  src: url("./assets/font/hind-siliguri/HindSiliguri-Regular.ttf")
    format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "HindSiliguri";
  src: url("./assets/font/hind-siliguri/HindSiliguri-Medium.ttf")
    format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "HindSiliguri";
  src: url("./assets/font/hind-siliguri/HindSiliguri-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "HindSiliguri";
  src: url("./assets/font/hind-siliguri/HindSiliguri-Bold.ttf")
    format("truetype");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Poppins", monospace;
}

/* ::-webkit-scrollbar {
  display: none;
} */
