@import url("../../../App.css");

.vcl-list-select-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 49%;
}

.vcl-list-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 7px;
  align-self: flex-start;
  font-weight: bold;
}

.vcl-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.vcl-heading-label-left {
  width: 50%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--heading-color);
  border-bottom-width: 1.6px;
  border-bottom-style: solid;
  border-bottom-color: var(--gray);
  border-top-width: 1.6px;
  border-top-style: solid;
  border-top-color: var(--gray);
  border-left-width: 1.6px;
  border-left-style: solid;
  border-left-color: var(--gray);
  margin-top: 10px;
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.vcl-heading-label-right {
  width: 50%;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--heading-color);
  border-bottom-width: 1.6px;
  border-bottom-style: solid;
  border-bottom-color: var(--gray);
  border-top-width: 1.6px;
  border-top-style: solid;
  border-top-color: var(--gray);
  border-right-width: 1.6px;
  border-right-style: solid;
  border-right-color: var(--gray);
  margin-top: 10px;
  margin-bottom: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.vcl-heading-value {
  width: 50%;
  text-align: center;
  font-size: 14px;
  color: var(--black);
  border-bottom-width: 0.75px;
  border-bottom-style: solid;
  border-bottom-color: var(--gray);
  padding-top: 10px;
  padding-bottom: 10px;
}

.crl-filter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.crl-filter-btn-container {
  display: flex;
  width: fit-content;
  flex-direction: row;
  align-self: flex-end;
}

.crl-filter-btn {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 5px;
  background-color: var(--white);
  border-radius: 5px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--orange-warning);
  color: var(--orange-warning);
  font-size: 14px;
  padding: 4px 10px;
  cursor: pointer;
}

.crl-filter-btn-red {
  border-color: var(--red-error);
  color: var(--red-error);
}

.crl-filter-btn-green {
  border-color: var(--green-success);
  color: var(--green-success);
}

.crl-filter-option-type-btn {
  font-size: 14px;
  color: var(--green-success);
  border-width: 0.75px;
  border-style: solid;
  border-radius: 5px;
  border-color: var(--green-success);
  background-color: var(--white);
  margin-right: 5px;
  margin-bottom: 5px;
  text-align: center;
  padding: 5px 10px;
  cursor: pointer;
}

.crl-filter-option-type-btn-sel {
  font-weight: 700;
}

.crl-filter-row-center {
  justify-content: center;
}
