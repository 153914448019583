@import url("../../../App.css");

.as-date-picker {
  width: 100%;
  margin-bottom: 10px;
  height: 40px;
  border-color: var(--pastel-004);
  border-width: 0.5px;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 14px;
  background-color: var(--white);
}

.as-date-container {
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.as-claim-date-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ap-option-item-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ap-choice-label-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
}

.ap-choice-label {
  font-size: 15px;
  color: var(--black);
  margin-left: 5px;
}

.ad-rb-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ad-rb-row-label {
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
}
