@import url("../../../../App.css");

.loc-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.add-loc-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.marg-right {
  margin-right: 10px;
  width: 65%;
  font-size: 15px;
}

.add-loc-detail {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 7px;
  font-size: 12px;
  color: var(--pastel-004);
  font-weight: 500;
  cursor: pointer;
}

.loc-table-col-title {
  color: var(--pastel-004);
  font-size: 16px;
}

.loc-table-col-item {
  color: var(--black);
  font-size: 14px;
}

.loc-table-row-container {
  cursor: pointer;
}

.loc-active {
  color: green;
  font-size: 14px;
  font-weight: 500;
}

.loc-in-active {
  color: red;
  font-size: 14px;
  font-weight: 500;
}

.loc-list-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.loc-list-button {
  font-size: 14px;
  font-weight: 500;
  color: var(--button-blue);
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 1.5px;
  cursor: pointer;
}

@media (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
}
