@import url("../../../App.css");

.id-date-label {
  font-size: 16px;
  color: var(--black);
  text-align: end;
  margin-top: 10px;
  margin-bottom: 15px;
}

.id-receipt-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--gray);
  padding: 5px 10px;
}

.id-receipt-detail-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.id-receipt-detail-field-label {
  font-size: 14.5px;
  color: var(--gray);
}

.id-receipt-detail-value-label {
  font-size: 16px;
  color: var(--black);
}

.id-receipt-img-container {
  width: 64px;
  height: 64px;
}

.id-receipt-name-label {
  font-size: 15px;
  color: var(--black);
}

.id-receipt-amount-label {
  font-size: 16px;
  color: var(--black);
  font-weight: 600;
}
