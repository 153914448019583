@import url("../../../../App.css");

.rl-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.add-rl-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
}

.rl-table-col-title {
  color: var(--pastel-004);
  font-size: 16px;
}

.rl-table-col-item {
  color: var(--black);
  font-size: 14px;
}

.rl-table-row-container {
  cursor: pointer;
}

.rl-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
}

.rl-card {
  width: 15em;
  border-color: var(--blue);
  border-width: 1px;
  padding: 7px;
  margin-right: 5px;
}

.rl-card-title {
  font-size: 15px;
  color: var(--blue);
  font-weight: 600;
  margin-top: 10px;
}

.rl-card-label {
  font-size: 12px;
  color: var(--gray);
  font-weight: 600;
}

.rl-card-label-val {
  font-size: 14px;
  color: var(--black);
}

.rl-card-btn-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
}

.rl-active {
  color: green;
  font-size: 13px;
  font-weight: 500;
}

.rl-in-active {
  color: red;
  font-size: 13px;
  font-weight: 500;
}

.rl-card-btn {
  color: var(--white);
  background-color: var(--blue);
  font-size: 13px;
  width: 50%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border-color: var(--blue);
}

.rl-card-btn:hover {
  color: var(--blue);
  background-color: var(--white);
  border-color: var(--blue);
}

.rl-card-image {
  height: 180px;
  object-fit: contain;
  width: 100%;
}

@media (max-width: 767px) {
  .rl-card {
    width: 12em;
    margin-bottom: 5px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
}
