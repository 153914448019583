@import url("../../../App.css");

.hero-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-color: #d3ddff;
  width: 100%;
}

.hero-left-img-container {
  width: 50%;
}

.hero-left-text-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
}

.content-text {
  color: #24488a;
}

.hero-btn-subtext {
  font-size: 11px;
  color: #575757;
  align-self: flex-end;
  margin-right: 15px;
}

.h-heading {
  font-size: 3.7em;
  font-weight: 600;
  letter-spacing: 0.4px;
  text-align: end;
  padding-left: 2rem;
}

.h-content {
  font-size: 16px;
  text-align: end;
  font-weight: 500;
}

.h-content-margin {
  margin-top: 2rem;
}

.hero {
  background: url("../../../assets/hero.jpg") no-repeat center center;
  background-size: 100% 100%;
  height: 80vh;
  position: relative;
  color: #f3f4f9;
}
.hero .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  height: 100%;
}

.hero .content h1 {
  font-size: 2.7em;
  padding: 0 2rem;
  font-weight: 600;
}

.hero .content p {
  font-size: 16px;
  padding: 0 10px;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}

.hero * {
  z-index: 10;
}

.get-started-btn {
  align-self: flex-end;
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ff9404;
  border-color: #ff9404;
  font-size: 16px;
  border-width: 0.75px;
  border-style: solid;
  border-radius: 20px;
  height: 45px;
  color: var(--white);
  font-weight: 500;
  margin-top: 2rem;
  cursor: pointer;
}

.hp-section-two {
  padding: 2rem 1.2rem;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hp-section-two-heading {
  font-weight: 600;
  font-size: 3.7rem;
}

.hp-section-two-sub-heading {
  font-weight: 500;
  font-size: 17px;
  margin-top: 10px;
  margin-left: 20%;
  margin-right: 20%;
  text-align: center;
  margin-bottom: 2rem;
}

.hp-section-two-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.hp-section-two-img-parent-container {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hp-section-two-img-container {
  width: 88%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hp-section-two-text-container {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 6%;
  padding-right: 6%;
}

.hp-section-text-heading {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 0.4px;
}

.hp-section-text-content {
  font-size: 17px;
  letter-spacing: 0.4px;
}

.hp-section-three {
  padding: 2rem 2rem;
  background-color: #f6f5f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hp-section-three-heading {
  font-weight: 600;
  font-size: 3.7rem;
}

.hp-feature-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.hp-serve-heading {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.29px;
  margin-bottom: 7px;
}

.hp-serve-content {
  font-size: 15px;
  letter-spacing: 0.29px;
  color: var(--black);
}

@media (max-width: 767px) {
  .hero-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .hero-left-img-container {
    width: 70%;
    order: -1;
  }

  .hero-left-text-container {
    width: 100%;
  }

  .h-heading {
    font-size: 1.8em;
    font-weight: 600;
    letter-spacing: 0.4px;
    text-align: center;
    padding-left: 0px;
  }

  .h-content {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    margin-left: 15px;
    margin-right: 15px;
  }

  .get-started-btn {
    font-size: 14px;
    margin-top: 10px;
    margin-right: 15px;
  }

  .hero-btn-subtext {
    margin-right: 25px;
  }

  .h-content-margin {
    margin-top: 10px;
  }

  .hp-section-two-heading {
    font-weight: 600;
    font-size: 2rem;
    margin-top: 10px;
  }

  .hp-section-two-sub-heading {
    font-size: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .hp-order-index {
    order: -1;
  }

  .hp-section-two-row-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border-style: solid;
    border-width: 0.75px;
    border-color: #aeaeae;
    padding: 1rem 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
    transition: box-shadow 0.3s ease;
  }

  .hp-section-two-img-parent-container {
    width: 100%;
  }

  .hp-section-two-text-container {
    width: 100%;
  }

  .hp-section-three-heading {
    font-weight: 600;
    font-size: 2.1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .get-started-btn {
    font-size: 14px;
  }

  .hero-container {
    height: 70vh;
  }

  .hero-left-img-container {
    width: 60%;
  }

  .hero-left-text-container {
    width: 40%;
  }

  .h-heading {
    font-size: 2.6em;
  }

  .get-started-btn {
    width: 55%;
  }

  .hp-section-two-heading {
    font-size: 2.6rem;
  }

  .hp-section-two-sub-heading {
    font-size: 16.5px;
    margin-left: 12%;
    margin-right: 12%;
  }

  .hp-section-text-heading {
    font-size: 26px;
  }

  .hp-section-text-content {
    font-size: 15.5px;
  }

  .hp-section-three-heading {
    font-size: 2.6rem;
  }
}
