@import url("../../../App.css");

.lp-hero-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 90vh;
  background-color: #ffffff;
  width: 100%;
}

.lp-customer-step-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lp-customer-step-row-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 30%;
  border-style: solid;
  border-width: 0.1px;
  border-color: #eeeeee;
  padding: 5px 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
  transition: box-shadow 0.3s ease;
  margin-right: 7px;
  margin-top: 7px;
}

.lp-customer-row-item-img {
  width: 70%;
}

.lp-customer-row-label {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.4px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}

.lp-customer-row-sub-text {
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.4px;
  margin-bottom: 10px;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .lp-hero-container {
    height: 70vh;
  }

  .lp-customer-step-row-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .lp-customer-step-row-item {
    width: 48%;
    margin-right: 7px;
    margin-top: 7px;
  }
}

@media (max-width: 767px) {
  .lp-hero-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .lp-customer-step-row-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .lp-customer-step-row-item {
    width: 95%;
    margin-top: 7px;
  }
}
