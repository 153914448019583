@import url("../../../App.css");

.offer-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 5px;
}

.offer-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #e0f2f1;
  padding-top: 5px;
  padding-bottom: 5px;
}

.offer-filter-item-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
}

.offer-filter-label {
  font-size: 14px;
  color: #3e2723;
}

.offer-filter-image-container {
  width: 32px;
  height: 32px;
}

.offer-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.offer-title {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.3px;
  color: var(--heading-color);
}

.offer-validity-label {
  font-size: 12.9px;
  color: var(--sub-heading-color);
}

.offer-bill-label {
  font-size: 11.9px;
  margin-top: 5px;
  color: var(--gray);
}

.offer-brand-label {
  font-size: 14px;
  color: var(--white);
  background-color: #81d4fa;
  text-align: center;
  width: 120px;
  align-self: flex-end;
  padding: 2px 15px;
  border-radius: 3px;
  margin-bottom: 5px;
  margin-top: 2px;
}

.offer-view-details {
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
  color: var(--pastel-002);
  cursor: pointer;
}

.offer-business-label {
  font-size: 14px;
  font-weight: 700;
  color: var(--pastel-003);
  text-align: end;
  letter-spacing: 0.4px;
}

.offer-add-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: end;
  margin-top: 10px;
  margin-bottom: 10px;
}

.offer-category-container {
  font-size: 12.1px;
  width: 100%;
  background-color: var(--orange-warning);
  padding: 2px 10px;
  border-radius: 3px;
}

.ol-offer-category-container {
  font-size: 12.1px;
  font-weight: 500;
  color: #4266f9;
}

.offer-expired-label {
  font-size: 12.1px;
  color: var(--white);
  background-color: var(--red-error);
  padding: 1px 35px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: fit-content;
}

.offer-ongoing-label {
  font-size: 12.1px;
  color: var(--white);
  background-color: var(--green-success);
  padding: 1px 35px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: fit-content;
}

.ol-search-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.ol-search-filter-container {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.ol-search-row-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  border-style: solid;
  border-width: 0.75px;
  border-color: #cecece;
  padding: 7px 20px;
  margin-right: 10px;
  background-color: var(--white);
  flex-grow: 1;
}

.ol-search-input-container {
  flex-grow: 1;
}

.ol-search-icon-container {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.ol-input-no-border {
  border: none;
  outline: none;
  padding: 10px;
  width: 100%;
}

.ol-input-no-border:focus {
  outline: none;
  border: none;
  box-shadow: none;
  border-width: 0px;
}

.offer-list-row-item-container {
  width: 31%;
  border-radius: 4px;
  border-color: #eeeeee;
  border-width: 0.75px;
  border-style: solid;
  padding: 5px 10px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
  transition: box-shadow 0.3s ease;
  margin-right: 5px;
  margin-top: 5px;
}

.ol-row-item-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
}

.ol-row-item-img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 150px;
}

.ol-row-item-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
  flex-grow: 1;
}

.ol-offer-cat-row-container {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  align-items: center;
}

.ol-offer-cat-row-icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.ol-category-parent-container {
  width: 100%;
  overflow: hidden;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Box shadow */
  transition: box-shadow 0.3s ease;
}

.ol-category-scroll-container {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  background-color: #f8f8f8;
  padding: 10px 5px;
  scrollbar-width: none;
}
.ol-category-scroll-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.ol-category-scroll-container {
  overflow-x: scroll;
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  scrollbar-width: none; /* For Firefox */
}

.ol-category-item {
  padding: 2px 15px;
  margin-right: 7px;
  border-radius: 20px;
  border-style: solid;
  border-width: 0.75px;
  border-color: #9c9c9c;
  background-color: var(--white);
  font-size: 14px;
  color: #9c9c9c;
  white-space: nowrap;
  cursor: pointer;
}

.ol-category-item-selected {
  border-color: #fda801;
  background-color: #fda801;
  font-size: 15px;
  font-weight: 500;
  color: var(--white);
  cursor: pointer;
}

@media (max-width: 767px) {
  .offer-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .offer-list-row-item-container {
    width: 95%;
    margin-right: 0px;
    margin-top: 5px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .offer-list-row-item-container {
    width: 48%;
  }
}
