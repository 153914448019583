@import url("../../../../App.css");

.add-rest-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.rest-input {
  width: 100%;
  margin-bottom: 1.2em;
  font-size: 14px;
}

.rest-input-text {
  font-size: 14px;
  color: var(--black);
}

.rest-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
}

.rest-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.arest-select {
  width: 100%;
  font-size: 14px;
}

.arest-cb-label {
  font-size: 15px;
  color: var(--black);
  margin-left: 10px;
}

@media (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
}
