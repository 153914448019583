@import url("../../../App.css");

.aemp-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.aemp-input-half {
  width: 49%;
}

.input-row-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2em;
}

.aemp-input {
  width: 100%;
  margin-bottom: 1.2em;
}

.aemp-input-text {
  font-size: 13px;
  color: black;
}

.aemp-field-label {
  font-size: 13px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 7px;
}

.aemp-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

.aemp-select {
  width: 100%;
}

.aemp-select-heading {
  font-size: 12px;
  color: var(--black);
  margin-bottom: 5px;
  font-weight: bold;
}

.aemp-text {
  font-size: 14px;
  color: black;
}

.aemp-checkbox-label {
  margin-left: 10px;
  font-size: 14px;
  color: var(--pastel-004);
}

.ae-input-row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.ae-row-input-container {
  flex-grow: 1;
  margin-right: 10px;
}

.ae-row-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;
  background-color: var(--button-blue);
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  font-size: 14px;
  height: fit-content;
  align-self: center;
}

.ae-row-img-container {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.aemp-checkbox-label-black {
  color: var(--black);
}

.ae-model {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px 0px;
}

.ae-model-img-container {
  width: 120px;
  height: 120px;
  margin-bottom: 10px;
}

.ae-model-message {
  font-size: 1.2rem;
  color: var(--blue-general);
  font-weight: 500;
}

.ae-model-sub-message {
  font-size: 14px;
  color: var(--black);
}

.ae-model-text {
  font-size: 15px;
  font-weight: 600;
  color: var(--green-success);
}

@media (max-width: 700px) {
}

@media (min-width: 701px) and (max-width: 900px) {
}
