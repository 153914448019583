@import url("../../../App.css");

.vccd-business-label {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--black);
}

.vccd-address-label {
  font-size: 15px;
  color: var(--black);
}

.vccd-reward-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.vccd-reward-label-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  font-size: 1.2rem;
  font-weight: 700;
  border-width: 0.75px;
  border-style: solid;
  border-color: var(--gray);
  padding-top: 10px;
  padding-bottom: 10px;
}

.vccd-log-parent-container {
  display: flex;
  flex-direction: column;
}

.vccd-log-row-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 4px;
  border-width: 0.75px;
  border-style: solid;
  border-color: #dcdcdc;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.vccd-cl-brand-label {
  align-self: flex-end;
  font-size: 12.9px;
  color: var(--button-blue);
  font-weight: 500;
}

.vccd-cl-offer-label {
  font-size: 15px;
  color: var(--black);
  font-weight: 500;
}

.vccd-cl-validity-label {
  font-size: 13.2px;
  color: rgba(0, 0, 0, 0.5);
}

.vccd-cl-customer-mobile-label {
  font-size: 14px;
  font-weight: 500;
  color: var(--black);
}

.vccd-cl-cashback-reward-amount {
  font-size: 16px;
  font-weight: 600;
  color: var(--black);
}

.vccd-invoice-button-container {
  display: flex;
  flex-direction: row-reverse;
}

.vccd-invoice-button {
  width: fit-content;
  background-color: var(--button-blue);
  font-size: 14px;
  color: var(--white);
  padding: 7px;
  width: 40%;
  text-align: center;
  margin-bottom: 15px;
  border-radius: 5px;
  cursor: pointer;
}

.tr-action-btn {
  font-size: 14px;
  font-weight: 500;
  color: var(--button-blue);
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 1.5px;
  cursor: pointer;
}

@media (max-width: 767px) {
  .vccd-reward-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .vccd-reward-label-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.2rem;
    font-weight: 700;
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .vccd-invoice-button {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
}
