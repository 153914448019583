@import url("../../../App.css");

.up-select-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.user-image-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1em;
}

.user-image-container {
  width: 120px;
  height: 120px;
}

#upload-user-photo {
  display: none;
}

.up-form-content {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
  margin-top: 1.7rem;
  background-color: #f4f4f4;
  padding: 1rem 1.2rem;
  border-radius: 4px;
}

.up-skip-label {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0.75px;
  text-decoration: underline;
  text-underline-offset: 6px;
  font-style: italic;
  cursor: pointer;
}

@media (max-width: 767px) {
  .up-form-content {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .up-form-content {
    width: 95%;
  }
}
