@import url("../../../App.css");

.screen-label {
  font-size: 3em;
  color: var(--pastel-004);
  font-weight: 600;
}

@media (max-width: 767px) {
  .screen-label {
    font-size: 1.7em;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .screen-label {
    font-size: 2.5em;
  }
}
