@import url("../../../App.css");

.ci-logo-parent-container {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}

.ci-logo-container {
  width: 150px;
  height: 80px;
}

.ci-business-label {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--black);
}

.ci-address-label {
  font-size: 15px;
  color: var(--black);
}

.ci-data-label {
  font-size: 13.1px;
  color: var(--black);
  margin-bottom: 5px;
  color: #aeaeae;
}
