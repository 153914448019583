@import url("../../../App.css");

.sf-screenFooter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 5px;
  background-color: #d3ddff;
  cursor: pointer;
  width: 100%;
}

.sf-footer-logo-container {
  height: 100%;
}

.sf-sub-link-text {
  font-size: 15px;
  color: var(--black);
  margin-bottom: 7px;
  letter-spacing: 0.4px;
}

.sf-sub-link-text:hover {
  font-weight: 500;
}

.sf-colElementHeading {
  font-size: 17px;
  align-self: flex-start;
  margin-bottom: 15px;
  color: var(--heading-color);
  font-weight: 600;
  letter-spacing: 0.4px;
}

.sf-colElementHeadingLogo {
  font-size: 17px;
  align-self: center;
  margin-bottom: 15px;
  color: var(--heading-color);
  font-weight: 600;
  letter-spacing: 0.4px;
}

@media (max-width: 767px) {
  .sf-colElementHeadingLogo {
    align-self: flex-start;
    margin-top: 1.2rem;
  }
}
