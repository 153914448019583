@import url("../../../App.css");

.cp-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4em;
  margin-bottom: 10px;
}

.cp-name-container {
  text-align: center;
  margin: 10px 0px;
  font-size: 24px;
  color: var(--black);
}

.cp-bank-row-parent-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--gray);
  padding: 5px 15px;
  cursor: pointer;
}

.cp-bank-row-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
  color: var(--white);
  flex-grow: 1;
}

.cp-cashback-row-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 5px;
  color: var(--white);
  border-radius: 4px;
  border-style: solid;
  border-width: 0.75px;
  border-color: var(--gray);
  padding: 5px 15px;
  cursor: pointer;
}

.cp-country-label {
  font-size: 13.5px;
  color: var(--gray);
}

.cp-name-label {
  font-size: 16px;
  color: var(--black);
}

.cp-amount-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0px;
  padding: 0px 10px;
}

.cp-currency-label {
  font-size: 20px;
  color: var(--heading-color);
}

.cp-request-cashback-btn {
  font-size: 14px;
  padding: 10px 5px;
  border-radius: 4px;
  background-color: var(--pastel-004);
  color: var(--white);
}

.cp-edit-bank-icon-container {
  width: 48px;
  height: 48px;
  align-self: center;
  margin-left: 10px;
}
