@import url("../../../../App.css");

.add-loc-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;
}

.loc-input {
  width: 100%;
  font-size: 14px;
}

.aloc-marg-right {
  width: 100%;
  font-size: 14px;
}

.loc-input-text {
  font-size: 14px;
  color: var(--black);
}

.loc-button-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 2em;
}

@media (max-width: 767px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
}
