@import url("../../../App.css");

.acc-mobile-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
}

.acc-mobile-container {
  flex-grow: 1;
}

.acc-mobile-search-img-container {
  margin-left: 5px;
  width: 38px;
  height: 38px;
  cursor: pointer;
}
