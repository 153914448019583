@import url("../../../App.css");

.vl-button-container {
  margin-top: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vl-create-account-label {
  font-size: 15px;
  margin-top: 2rem;
  font-style: italic;
  color: var(--black);
}

.vl-create-account-text {
  font-size: 18px;
  font-weight: 500;
  margin-left: 5px;
  font-style: normal;
  color: var(--sub-heading-color);
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 1.2px;
  cursor: pointer;
}

.vl-or-label {
  font-size: 12.5px;
  color: var(--gray);
  margin-top: 10px;
  margin-bottom: 10px;
}
